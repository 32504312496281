<template>
	<div>
			<div class="zxyj-content-box" >
			<div class="banner-content">
				<div class="banner">
						<img style="height: 400px;" :src="type=='news' ? newsImg : type=='case' ? projectImg : factoryImg" alt="加载失败" />
				</div>
			</div>
		</div>
		<div class="content" style="display: flex;flex-direction: column; justify-content: space-between;align-items: center;">
			<div style="display: flex;flex-direction: column;width: 1200px; margin-top: 30px;margin-bottom: 10px;">
              <div style="display: flex;">
              	<el-breadcrumb separator="/">
              	 <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              				 <el-breadcrumb-item v-if="route.name=='factoryDetail'" :to="{ path: '/factory/index.html' }">加工工厂</el-breadcrumb-item>
							 <el-breadcrumb-item v-if="route.name=='caseDetail'" :to="{ path: '/case/index.html' }">项目案例</el-breadcrumb-item>
							  <el-breadcrumb-item v-if="route.name=='newsDetail'" :to="{ path: '/news/index.html' }">聚焦鑫昇腾</el-breadcrumb-item>
              	 <el-breadcrumb-item >{{detail.title}}</el-breadcrumb-item>
              	</el-breadcrumb>
              </div>
			  <div style="display: flex;min-height: 380px; margin-top: 30px;">
					<div class="content2" style="min-height: 380px; width: 920px;">
						<div class="title" style="text-align: center;font-size: 22px;">{{ detail.title }}</div>
						<div class="des">
							<div class="" v-if="detail.content">
								<p v-html="detail.content"></p>
							</div>
							<div class="" v-else>暂无内容</div>
						</div>
					</div>

					<div class="hotNews" >
						<div class="title" style="display: flex;font-size: 20px;font-weight: 800;border-bottom: 1px solid #ccc;height: 40px;font-family: MicrosoftYaHei;">{{ navTitle }}</div>
						<div style="margin-top: 5%;">
							<div v-for="(item, i) in navs" :key="i" style="margin-top: 2%;" >
								<a class="a-h" :href="[url+item.id+'&type='+type]" style="font-family: MicrosoftYaHei;color: #333333;">
									{{item.title}}
								</a>
							</div>
						</div>
					</div>
				</div>
				<div style="height: 100px"></div>
				<div style="display: flex; justify-content: space-between;width: 900px;">
					<a v-if="upData.id != 0" class="next" :href="[url+upData.id + '&type=' + type]">上一条：{{upData.title}}</a>
					<a v-if="upData.id == 0"></a>
					<a v-if="downData.id != 0" class="next" :href="[url+downData.id + '&type=' + type]">下一条：{{downData.title}}</a>
					<a v-if="downData.id == 0"></a>
				</div>
				<div style="height: 100px"></div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import http from "@/common/utils/Http";
	export default {
		metaInfo: {
			title: "",
		},
		data() {
			return {
				detail: {},
				current: "",
				navTitle: '',
				 route: this.$route,
				type: this.$route.query.type,
				case: 'case',
				news: 'news',
				factory: 'factory',
				bannerImg:'@/assets/images/detail/detaila.png',
				url: '',
				params: {
					pageNum: 1,
					pageSize: 10
				},
				navs: [],
				factoryImg:require('@/assets/images/banner/factory.png'),
				newsImg:require('@/assets/images/banner/news.png'),
				projectImg:require('@/assets/images/banner/project.png'),
				pageTitle: "",
				upData: {id:0},
				downData: {id:0}
			};
		},
		activated() {
			this.getUpDownData();
			this.getDetail();
		},
		methods: {
			// 获得上下文章
			getUpDownData(){
				let type = this.$route.query.type;
				let that = this;
				http.api
					.get("/site/" + type + "/upDown"+ type + "?" + type +"Id=" + this.$route.query.id)
					.then((res) => {
						console.log(res.data)
						that.upData = res.data.data[0];
						that.downData = res.data.data[1];
					})
			},
			// 获取详情
			getDetail() {
				let params = this.params;
				let type = this.$route.query.type;
				let that = this;
				http.api
					.get("/site/" + type + "Detail/" + this.$route.query.id)
					.then((res) => {
						let detail = {};
						let data = res.data.data;
						//根据类型填充内容
						if (type === "news") {
							detail.title = data.title;
							detail.content = data.newsContent;
							detail.image = data.newsUrl;
							this.navTitle = "更多新闻";
							this.url = '/newsDetail/index.html?id=';
							params.notId = this.$route.query.id;
							this.bannerImg="@/assets/images/case/news.png"
							http.api.get("/site/news/list", {
								params
							}).then((res) => {
								this.navs = this.changeArr(res.data.rows);
								this.total = res.data.total;
							});

						} else if (type === "factory") {
							detail.title = data.title;
							detail.content = data.factoryContent;
							detail.image = data.factoryUrl;
							this.navTitle = "更多工厂"
							this.url = '/factoryDetail/index.html?id=';
							this.bannerImg="@/assets/images/case/factory.png"
							http.api.get("/site/factory/list", {
								params
							}).then((res) => {
								this.navs = this.changeFactory(res.data.rows);
								this, this.total = res.data.total
							});
						} else if (type === "case") {
							detail.title = data.title;
							detail.content = data.caseContent;
							detail.image = data.caseUrl;
							this.navTitle = "更多案例"
							this.bannerImg="@/assets/images/case/case1.png"
							this.url = '/caseDetail/index.html?id=';
							http.api.get("/site/case/list", {
								params
							}).then((res) => {
								this.navs = this.changeCase(res.data.rows);
								this.total = res.data.total
							});
						}
						this.detail = detail;
						this.current = this.detail.image[0];
						that.setTitle(type); // 设置标题
					});
			},
			changeFactory(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};

					sub.id = row.siteFactoryId;
					sub.title = row.factoryName;
					newArr.push(sub);
				});
				return newArr;
			},
			changeCase(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};
					sub.id = row.siteCaseId;
					sub.title = row.caseTitle;
					newArr.push(sub);
				});
				return newArr;
			},
			changeArr(arr) {
				let newArr = [];
				arr.forEach((row) => {
					let sub = {};

					sub.title = row.newsTitle;
					sub.id = row.siteNewsId;
					newArr.push(sub);
				});
				return newArr;
			},
			change(num) {
				this.current = this.detail.image[num];
			},
			setTitle(type) {
				let sufex = type == "news" ? "公司新闻动态" : type == "case" ? "鑫昇腾智能爬架工程案例" : "智能爬架加工工厂";
				this.pageTitle =  this.detail.title + "-" + sufex;
				document.title = this.pageTitle;
			}
		},
	};
</script>

<style lang="scss" scoped>
.banner-content {	margin-top: 80px;	position: relative;	height: 400px;width: 100%;}
.banner {	height: 100%;	width: 100% !important;	overflow: hidden;	text-align: center;	margin: 0 auto;	padding: 0;	z-index: 1;}
	.content {
        background-color:#f5f4f4;
		display: flex;
        
		.lf {
			width: 50%;
		}

		.rt {
			width: 50%;
			padding: 0 20px;

			.title {
				text-align: center;
				margin: 40px 0;
				font-size: 1.2rem;
			}

			.des {
				bottom: 0;
				font-size: 0.8rem;
				line-height: 1.6rem;
			}
		}
	}

	.content2 {
		width: 60%;
		padding: 20px 20px;
		background: white;
		margin-top: 10px;

	}

	.hot-news {
		width: 290px;
		height: 400px;
	}

	.background-white {
		background: white;
	}

	.margin-left-10 {
		margin-left: 10px;
	}

	.padding-20 {
		padding: 20px;
	}

	.a-h {}

	.a-h:hover {
		color: #20A0FF;
	}
	.hotNews {
		width: 290px;
		height: 380px;
		background-color: white;
		margin-left: 10px;
		padding: 20px;
		margin-top: 12px;
		font-family: MicrosoftYaHei;
		
	
		.title { 
			border-bottom: 1px solid #E5E5E5;
			padding-bottom: 10px;
			margin-right: -20px;
			position: relative;
			font-size: 18px;
			font-weight: bold;
		}
		.title:after{
			content: '';
			height: 1px;
			width: 88px;
			background: #0078FF;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	.next{font-size: 14px;font-family: MicrosoftYaHei;color: #112A88;}
</style>
